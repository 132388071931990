import styled from 'styled-components';
import { colors } from '../consts/colors';
export const TosStyle = styled.div`
  h1 {
    font-size: 3rem !important;
    font-family: 'Poppins', sans-serif;
  }

  h2 {
    font-size: 2rem !important;
    font-family: 'Poppins', sans-serif;
  }

  p {
    font-size: 1.4rem !important;
    font-family: 'Poppins', sans-serif;
  }

  li {
    font-size: 1.4rem !important;
    font-family: 'Poppins', sans-serif;
  }

  ul {
    font-size: 1.4rem !important;
    font-family: 'Poppins', sans-serif;
  }
`;
