import React, { useState } from "react"
import { SectionContainer } from "../components/common/common.style"
import TopBar from "../components/common/topBar"
import "../components/common/i18n"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import { TosStyle } from "../components/tos.style"

const Tos = () => {
  const [visibilitySensor, setVisibilitySensor] = useState({ book: true })
  const { t } = useTranslation()

  const tos = useStaticQuery(graphql`
    query {
      data: strapiTermsAndConditions {
        termeni_si_conditii
        termeni_si_conditii_RO
      }
    }
  `)

  const isBrowser = typeof window !== "undefined"

  return (
    <Layout>
      <Seo
        title={
          isBrowser && window && window.localStorage.getItem("lang") === "ro"
            ? "Tobsos - Termeni și Condiții"
            : "Tobsos - Terms and Conditions"
        }
        description={
          isBrowser && window && window.localStorage.getItem("lang") === "ro"
            ? "Cu cât fundația fizică a jucătorilor este mai puternică, cu atât sunt mai mari șansele de a deveni un sportiv de top."
            : "The stronger the players physical foundation the higher the chances of becoming a top athlete."
        }
        keywords="fitness, training, sport, young, tineret"
        url="https://tobsos.com/"
      />
      <TopBar isTos={true} />
      <TosStyle>
        <SectionContainer style={{ marginTop: "105px" }} fluid>
          {isBrowser && window && localStorage.getItem("lang") === "en" ? (
            <ReactMarkdown
              children={tos.data.termeni_si_conditii}
            ></ReactMarkdown>
          ) : (
            <ReactMarkdown
              children={tos.data.termeni_si_conditii_RO}
            ></ReactMarkdown>
          )}
          <h2
            style={{
              cursor: "pointer",
              border: "2px solid black",
              borderRadius: "25px",
              padding: "5px 15px",
              color: "#D50000",
              display: "inline-block",
            }}
            onClick={() => window.history.back()}
          >
            {t("tosComponent.backButton")}
          </h2>
        </SectionContainer>
      </TosStyle>
    </Layout>
  )
}

export default Tos
